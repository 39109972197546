<template>
  <b-card no-body>
    <SidebarSearch :components="searchComponent" @search="searchData" />

    <TableHeader :selected-length="selectedDatas.length" :per-page="perPage" :has-create-button="false"
      @per-page-change="perPage = $event">
      <template #button-export-items>
        <b-button variant="outline-primary" size="sm" @click="openModalExportMaster()">
          <Feather-icon icon="DownloadIcon" />
          <span class="align-middle ml-25">Export</span>
        </b-button>
      </template>
      <template #dropdown-items>
        <b-dropdown-item :disabled="selectedDatas.length === 0" @click="copyToMaster">
          <Feather-icon icon="CopyIcon" />
          Salin ke Produk Utama ({{ selectedDatas.length }})
        </b-dropdown-item>
        <b-dropdown-item disabled>
          <Feather-icon icon="LinkIcon" />
          Tautkan SKU ke SKU Produk Utama ({{ selectedDatas.length }})
        </b-dropdown-item>
      </template>

      <template #buttons-group>
        <!-- sinkron button -->
        <b-button variant="success" size="sm" @click="syncProduct">
          <Feather-icon icon="RefreshCwIcon" />
          Sinkron Produk
        </b-button>

        <!-- add button click to router named FormProductTokopedia -->
        <b-button variant="warning" size="sm" :to="{ name: 'FormProductTokopedia' }">
          <Feather-icon icon="PlusIcon" />
          Tambah Produk
        </b-button>
      </template>
    </TableHeader>

    <b-table-simple striped responsive style="zoom: 80%">
      <b-thead>
        <b-tr>
          <b-th class="bg-transparent" style="width: 5%">
            <b-form-checkbox v-model="selectAll" @change="selectAllRows" />
          </b-th>
          <b-th class="bg-transparent" style="width: 30%">Produk</b-th>
          <b-th class="bg-transparent" style="width: 10%">Master SKU</b-th>
          <b-th class="bg-transparent" style="width: 10%">SKU</b-th>
          <b-th class="bg-transparent">Harga</b-th>
          <b-th class="bg-transparent">Stok</b-th>
          <b-th class="bg-transparent">Update Time</b-th>
          <b-td class="bg-transparent"></b-td>
        </b-tr>
      </b-thead>
      <b-tbody v-if="tableData.length > 0">
        <b-tr v-for="(item, index) in tableData" :key="index">
          <b-td>
            <b-form-checkbox v-model="selectedDatas" :value="item" />
          </b-td>
          <b-td>
            <div class="d-flex align-items-center">
              <!-- Image -->
              <b-img width="75" :alt="item.product.basic.name" :src="item.product.pictures && item.product.pictures.length > 0
                ? item.product.pictures[0].ThumbnailURL
                : 'https://via.placeholder.com/150x150'
                " thumbnail class="mr-2" />

              <!-- Content -->
              <div class="flex-grow-1">
                <!-- Product Name -->
                <h5 class="text-primary">
                  {{ item.product.basic.name }}
                </h5>
                <div>
                  <b-badge style="height: 24px; background-color: #d3d3d3;" variant="secondary">{{ item.name
                    }}</b-badge>
                </div>

                <!-- Link to Tokopedia -->
                <div class="d-flex align-items-center">
                  <b-link :href="item.product.other.url" class="text-underline" target="_blank"
                    rel="noopener noreferrer">
                    <Feather-icon icon="ExternalLinkIcon" />
                    Lihat di Tokopedia
                  </b-link>
                </div>
              </div>
            </div>
          </b-td>

          <b-td>
            {{ item.masterSKU ? item.masterSKU : '-' }}
          </b-td>

          <b-td>
            {{ item.product.other.sku ? item.product.other.sku : '-' }}
          </b-td>

          <b-td>
            {{ item.product.price.rupiah }}
            <b-button variant="outline-primary" size="sm" class="ml-2" @click="openModalEditHarga(item)">
              <Feather-icon icon="Edit2Icon" />
            </b-button>
          </b-td>
          <b-td>{{ item.product.stock.value }}</b-td>
          <b-td>{{ new Date(item.product.basic.updateTimeUnix * 1000).toLocaleString('id-ID', {
            year: 'numeric', month:
              '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false
          }).replace(',',
            '').replace(' ', ' ').replace('/', '-').replace('/', '-') }}</b-td>
          <b-td>
            <b-dropdown right variant="outline-primary" size="sm" block>
              <template #button-content>
                <Feather-icon icon="MoreHorizontalIcon" />
              </template>
              <b-dropdown-item @click="copyToMaster(item)">
                <Feather-icon icon="CopyIcon" /> Salin ke Produk Utama
              </b-dropdown-item>
              <b-dropdown-item disabled title="Fitur belum tersedia">
                <Feather-icon icon="Edit2Icon" /> Ubah Produk
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item variant="danger" disabled title="Fitur belum tersedia">
                <Feather-icon icon="Trash2Icon" />
                Hapus Produk
              </b-dropdown-item>
            </b-dropdown>
          </b-td>
        </b-tr>
      </b-tbody>
      <EmptyTableRow v-else :colspan="6" />
    </b-table-simple>

    <component :is="modalExportProductComponent" v-bind="{ productData }" />
    <component :is="modalEditHargaComponent" v-bind="{ productData }" />
  </b-card>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import axios from '@axios';

// import mixins
import marketplaceMixins from './mixins/marketplaceMixins';

export default defineComponent({
  name: 'MarketplaceTokopediaList',
  mixins: [marketplaceMixins],
  data() {
    return {
      productData: {},
      modalExportProductComponent: () =>
        import('./ModalExportProduct.vue'),
      modalEditHargaComponent: () =>
        import('./ModalEditHarga.vue'),
      searchComponent: [
        {
          name: 'search',
          placeholder: 'Cari produk (nama, sku)',
          type: 'text',
          value: null,
        },
        {
          label: 'Berdasarkan Toko',
          name: 'shopId',
          placeholder: 'Berdasarkan Toko',
          type: 'select',
          value: null,
          options: [],
        },
        {
          label: 'Urutkan berdasarkan',
          name: 'sortBy',
          type: 'select',
          value: null,
          options: [
            { value: 'date', text: 'Tanggal (Terlama)' },
            { value: '-date', text: 'Tanggal (Terbaru)' },
            { value: 'price', text: 'Harga (Terendah)' },
            { value: '-price', text: 'Harga (Tertinggi)' },
            { value: 'stock', text: 'Stok (Terendah)' },
            { value: '-stock', text: 'Stok (Tertinggi)' },
          ],
        },
        {
          label: 'Kondisi Produk',
          name: 'condition',
          type: 'select-multiple',
          value: null,
          options: [
            { value: 'new', title: 'Baru' },
            { value: 'second', title: 'Bekas' },
          ],
        },
        {
          label: 'Status Produk',
          name: 'status',
          type: 'select-multiple',
          value: null,
          options: [
            { value: 'active', title: 'Aktif' },
            { value: 'best', title: 'Best (Feature Product)' },
            { value: 'not_active', title: 'Tidak Aktif (Gudang)' },
            { value: 'pending', title: 'Pending' },
            { value: 'banned', title: 'Banned' },
            { value: 'deleted', title: 'Terhapus' },
          ],
        },
      ],
    };
  },
  methods: {
    async fetchData() {
      if (this.tableData.length > 1) {
        if (this.tableData.length >= this.totalRows) {
          return;
        }
      }

      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        ns: false,
      };

      if (this.search) {
        // loop search to get key and value
        Object.keys(this.search).forEach(key => {
          if (this.search[key]) {
            // if contain object of array title and value get the value
            if (Array.isArray(this.search[key])) {
              params[key] = this.search[key].map(item => item.value);
            } else {
              params[key] = this.search[key];
            }
          }
        });
      }

      const url = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');

      const response = await axios.get(`tokopedia/product/sync?${url}`);

      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.data &&
        response.data.data.data.length > 0
      ) {
        this.tableData = [...this.tableData, ...response.data.data.data];

        const rupiah = new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        });

        this.tableData.forEach(item => {
          item.product.price.rupiah = rupiah.format(item.product.price.value);
          item.stats = {
            transaction:
              item.product.GMStats && item.product.GMStats.transactionSuccess
                ? item.product.GMStats.transactionSuccess
                : 0,
            sold:
              item.product.GMStats && item.product.GMStats.countSold
                ? item.product.GMStats.countSold
                : 0,
          };
        });
      }

      this.totalRows = response.data.data.total;
      this.currentPage += 1;
    },

    async searchData(search = null) {
      this.search = search;
      this.tableData = [];
      this.currentPage = 1;
      this.totalRows = 0;

      await this.fetchData();
    },

    selectAllRows() {
      if (this.selectAll) {
        this.selectedDatas = this.tableData;
      } else {
        this.selectedDatas = [];
      }
    },

    copyToMaster(data) {
      if (this.selectedDatas.length >= 0 && data) {
        this.selectedDatas = [];
        this.selectAll = false;
        this.selectedDatas.push(data);
      }

      if (this.selectedDatas.length === 0) {
        this.$swal({
          title: 'Peringatan',
          text: 'Pilih produk terlebih dahulu',
          icon: 'warning',
        });
        return;
      }

      this.$swal({
        title: 'Salin ke Produk Utama',
        text: `Apakah Anda yakin ingin menyalin ${this.selectedDatas.length} produk ke Produk Utama?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya, Salin',
        cancelButtonText: 'Batal',
      }).then(result => {
        if (result.isConfirmed) {
          this.$swal({
            title: 'Berhasil',
            text: 'Produk berhasil disalin ke Produk Utama',
            icon: 'success',
          });

          this.selectedDatas = [];
          this.selectAll = false;
        }
      });
    },

    async openModalExportMaster() {
      this.productData = this.tableData;
      this.$bvModal.show('modal-export-product');
    },

    openModalEditHarga(item) {
      this.productData = item;
      this.$bvModal.show('modal-edit-harga');
    },
  },
  computed: {
    productStatus() {
      return status => {
        switch (status) {
          case -2:
            return { text: 'Banned', variant: 'danger' };
          case -1:
            return { text: 'Pending', variant: 'warning' };
          case 0:
            return { text: 'Terhapus', variant: 'danger' };
          case 1:
            return { text: 'Aktif', variant: 'success' };
          case 2:
            return { text: 'Best (Feature Product)', variant: 'primary' };
          case 3:
            return { text: 'Tidak Aktif (Gudang)', variant: 'secondary' };
          default:
            return { text: 'Tidak Diketahui', variant: 'dark' };
        }
      };
    },
    productCondition() {
      return condition => {
        switch (condition) {
          case 1:
            return { text: 'Baru', variant: 'primary' };
          case 2:
            return { text: 'Bekas', variant: 'warning' };
          default:
            return { text: 'Tidak Diketahui', variant: 'dark' };
        }
      };
    },
  },
});
</script>

<style lang="scss">
div:where(.swal2-container) .swal2-select {
  min-width: 50%;
  max-width: 100%;
  color: inherit;
  font-size: 1.125em;
  width: 80% !important;
}
</style>
